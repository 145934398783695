import React from "react";
import Layer6Item from "../../basics/Layer6Item";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const Layer6 = ({ ...rest }: Props) => {
  return (
    <section className="relative mt-20" {...rest}>
      <div className="absolute -translate-y-24 lg:translate-y-0">
        <img src="/img/layers/layer6/patterns.png" alt="" />
      </div>
      <h2 className="text-lg lg:text-[42px] text-dark-blue font-semibold text-center">
        Get informed!
      </h2>
      <div className="responsive grid md:grid-cols-2 gap-5 mt-10">
        <Layer6Item
          img="connected"
          title="Get connected"
          inner=""
          description="Join PowerStock family today!Whether you are a retailer, distributor, or fragrance professional, s 
                your ultimate destination for all your fragrance wholesale needs."
        />
        <Layer6Item
          img="how"
          title="Know how"
          inner=""
          description="Unlock Fragrance Expertise: Gain In-Depth Knowledge with Our Fragrance Insights and Tips"
        />
        <Layer6Item
          img="updated"
          title="Get updated"
          inner=""
          description="Stay in the Fragrance Loop: Subscribe for the Latest Updates and Exclusive Offers!"
        />
        <Layer6Item
          img="inspiring"
          title="To be inspiring"
          inner=""
          description="Embrace the Power of Scent: Inspiring Connections, Enhancing Experiences."
        />
      </div>
    </section>
  );
};

export default Layer6;
