import React from 'react'

type Props = {
    number: string,
    title: string,
}

const Layer2Item = ({ number, title }: Props) => {
    return (
        <div className="flex flex-col items-center">
            <p className="text-[45px] text-[#444]">+{number}</p>
            <h3 className="text-md text-blue">{title}</h3>
        </div>
    )
}

export default Layer2Item