import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import contactSlice from "./Contact/contactSlice";

export const store = configureStore({
  reducer: {
    contact: contactSlice,
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
