import React from 'react'

type Props = {
    
}& React.HTMLAttributes<HTMLDivElement>;

const Layer4 = ({ ...rest }: Props) => {
    return (
        <section className="flex w-full md:w-11/12 mx-auto mt-20" {...rest}>
            <img className="md:objcet-contain" src="/img/layers/layer4/container.png" alt="" />
        </section>
    )
}

export default Layer4