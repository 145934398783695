import React, { useState } from 'react'
import { BsDash, BsPlus } from 'react-icons/bs'

type Props = {
    id: number;
    question: string;
    answer: string;
}

const FaqItem = ({ id, question, answer }: Props) => {
    const [show, setShow] = useState(false)
    return (
        <div className={`flex flex-col py-5 ${show ? "border-l-4 border-blue" : "border-l-2 border-black border-opacity-[8%]"}`}>
            <div className="flex flex-row justify-between pl-[27px]">
                <div className='flex flex-row gap-[34px] text-[15px]'>
                    <span className={`font-bold ${show ? "text-blue" : null}`}>{id}</span>
                    <h1 className={`${show ? "text-blue" : null}`}>{question}</h1>
                </div>
                {
                    !show ?
                        <BsPlus className="hover:cursor-pointer text-black hover:text-blue text-opacity-25 hover:text-opacity-100 text-xl"
                            onClick={() => setShow(!show)} />
                        :
                        <BsDash className="hover:cursor-pointer text-black hover:text-blue text-opacity-25 hover:text-opacity-100 text-xl"
                            onClick={() => setShow(!show)} />
                }

            </div>
            {
                show && <p className="text-md leading-7 pl-[72px] mt-4">
                    {answer}
                </p>
            }

        </div>
    )
}

export default FaqItem;