import React from "react";
import Page from "../components/layout/Page";
import Banner from "../components/sections/Banner";
import Layer1 from "../components/sections/contents/Layer1";
import Layer2 from "../components/sections/contents/Layer2";
import Layer3 from "../components/sections/contents/Layer3";
import Layer4 from "../components/sections/contents/Layer4";
import Layer5 from "../components/sections/contents/Layer5";
import Layer6 from "../components/sections/contents/Layer6";
import Brands from "../components/sections/Brands";
import Faq from "../components/sections/Faq";
import ContactUs from "../components/sections/ContactUs";

const Home: React.FC = () => {
  return (
    <Page>
      <Banner id="home" />
      <main>
        <Layer1 />
        <Layer2 id="aboutus" />
        <Layer3 />
        <Layer4 />
        <Layer5 />
        <Layer6 />
        <Brands id="brands" />
      </main>
      <Faq id="faq" />
      <ContactUs id="contactus" />
    </Page>
  );
};

export default Home;
