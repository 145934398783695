import { BrowserRouter } from "react-router-dom";
import "./App.css";
import DefaultRoutes from "./routes/DefaultRoutes";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <DefaultRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
