import React, { useEffect, useState } from "react";
import NavbarItem from "../basics/NavbarItem";
import handleScrollToTop from "../../utils/ScrollToTop";
import { bubble as Menu } from "react-burger-menu";
import { CgMenuMotion } from "react-icons/cg";
import { BsX } from "react-icons/bs";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  var styles = {
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#E6A87C",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#E6A87C",
      padding: "0.8em",
    },
    bmItem: {
      display: "block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };
  return (
    <>
      <Menu
        styles={styles}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <a className="menu-item" href="#home">
          Home
        </a>
        <a className="menu-item" href="#brands">
          Brands
        </a>
        <a className="menu-item" href="#aboutus">
          About us
        </a>
        <a className="menu-item" href="#faq">
          FAQ
        </a>
        <a className="menu-item" href="#footer">
          Contact us
        </a> 
      </Menu>
      <header className="flex items-center flex-row w-full px-10 py-4 z-40 top-0 bg-white relative">
        {isMenuOpen ? (
          <BsX
            className="flex lg:hidden text-lg"
            onClick={() => setIsMenuOpen((prev) => !prev)}
          />
        ) : (
          <CgMenuMotion
            className="flex lg:hidden text-lg"
            onClick={() => setIsMenuOpen((prev) => !prev)}
          />
        )}

        <div className="w-full flex place-content-center lg:place-content-start lg:w-1/2">
          <div
            className="flex flex-row hover:cursor-pointer gap-2 items-center w-fit"
            onClick={handleScrollToTop}
          >
            <div className="flex flex-col gap-0.5">
              <div className="h-3.5 w-3.5 rounded-full bg-blue"></div>
              <div className="h-3.5 w-3.5 rounded-full bg-orange"></div>
            </div>
            <h1 className="text-[36px] text-dark-blue font-bold">PowerStock</h1>
          </div>
        </div>

        <ul className="w-1/2 hidden lg:flex flex-row gap-10 pt-4">
          <NavbarItem text="Home" section="home" />
          <NavbarItem text="Brands" section="brands" />
          <NavbarItem text="About us" section="aboutus" />
          <NavbarItem text="FAQ" section="faq" />
          <NavbarItem text="Contact us" section="footer" /> 
        </ul>
      </header>
    </>
  );
};

export default Navbar;
