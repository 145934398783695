import React from 'react'
import ScrollToSection from '../../../utils/ScrollToSection'
type Props = {

} & React.HTMLAttributes<HTMLDivElement>;

const Layer5 = ({ ...rest }: Props) => {
    return (
        <section className="flex flex-col items-center relative" {...rest} id="welcome">
            <h1 className="text-lg md:text-[42px] text-center text-dark-blue font-semibold">Welcome to <br /> PowerStock!</h1>
            <div className="absolute translate-y-[260px] -translate-x-12 -z-10">
                <img src="/img/layers/layer5/patterns.png" alt="" />
            </div>
            <div className="w-11/12 lg:w-1/3 mx-auto flex flex-col mt-12 text-black">
                <p className="flex text-justify gap-2 leading-7">
                    <div className="bg-[#F2C8A1] w-[58px] h-[58px] text-[40px] rounded-full text-white flex flex-shrink-0">
                        <p className="m-auto">A</p>
                    </div>
                    t FragranceWholesale.com, we pride ourselves on being a
                    leading B2B fragrance wholesaler, catering to businesses in the
                </p>

                <p className="text-justify leading-7 mt-10 lg:mt-0">
                    beauty, cosmetics, and personal care industries. With our extensive
                    range of high-quality fragrances, exceptional customer service, and
                    competitive pricing, we are committed to helping your business flourish.
                </p>
            </div>
            <div className="mt-10 lg:mt-20 grid lg:grid-cols-2 gap-10 lg:gap-20 w-11/12 lg:w-8/12 mx-auto text-black">
                <div className="col-span-1">
                    <img className="object-contain" src="/img/layers/layer5/layer5.png" alt="" />
                </div>
                <div className="col-span-1">
                    <p className="text-justify">
                        As a B2B fragrance wholesaler, we understand the
                        importance of offering an extensive selection of fragrances
                        that appeal to a wide range of tastes and preferences. Our
                        catalog boasts a diverse range of premium fragrances,
                        including popular designer brands, niche perfumes, and
                        exclusive collections. From timeless classics to trendy scents,
                        we have everything to cater to your discerning clientele.
                    </p>
                    <br />
                    <p className="text-justify">
                        We are passionate about delivering only the highest quality
                        fragrances to our valued business partners. Each product in
                        our inventory is carefully curated, ensuring that it meets our
                        stringent standards for authenticity, longevity, and olfactory
                        excellence. By sourcing our fragrances directly from reputable
                        manufacturers and distributors, we guarantee that every
                        bottle you receive is genuine and in pristine condition.
                    </p>
                </div>
            </div>
            <div className="w-11/12 lg:w-1/3 mx-auto mt-8">
                <p className="text-black text-justify">
                    At FragranceWholesale.com, we understand the importance of
                    competitive pricing for our B2B customers. Our strong relationships with
                    fragrance suppliers and our commitment to efficiency allow us to offer
                    highly competitive wholesale prices. By partnering with us, you can
                    maximize your profit margins and stay ahead of the competition while
                    delivering exceptional value to your customers. <br />
                    We value the success of your business as much as you do, which is why
                    our dedicated team is always ready to provide personalized assistance
                    and support.
                </p>
                <button onClick={ScrollToSection('contactus')}
                    className="bg-blue rounded-22 h-[46px] w-[156px] text-white mt-10 hover:bg-orange z-40">
                    <a href="#contactus"> Contact us</a>
                </button>
            </div>
        </section>
    )
}

export default Layer5