import React from 'react'
import ScrollToSection from '../../utils/ScrollToSection'

type Props = {
    text: string;
    section?: string;
} & React.HTMLAttributes<HTMLLIElement>;

const navbarItem = ({ text, section, ...rest }: Props) => {


    return (
        <li className={`hover:border-b-2 px-2 hover:border-b-blue hover:text-blue hover:cursor-pointer text-lg
        transition duration-100 ease-in-out`} {...rest} >
            <a href={`#${section}`} onClick={ScrollToSection(section)}>
                <h4>{text}</h4>
            </a>
        </li>
    )
}

export default navbarItem