import React from 'react'
import Layer2Item from '../../basics/Layer2Item'
import ScrollToSection from '../../../utils/ScrollToSection'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>;

const Layer2 = ({ ...rest }: Props) => {
    return (
        <section className="relative" {...rest}>
            <div className="absolute z-0">
                <img src="/img/layers/layer2/layer2.png" alt="" />
            </div>
            <div className='flex flex-col responsive mt-20 z-20'>
                <div className="flex flex-col md:flex-row gap-[58px]">
                    <div className="w-full md:w-1/2">
                        <img src="/img/layers/layer2/B2B.png" alt="" />
                    </div>
                    <div className="flex flex-col w-full md:w-1/2 pt-10">
                        <h3 className="text-lg lg:text-[38px] text-dark-blue font-semibold">Your Trusted Partner in  B2B Fragrance Solutions</h3>
                        <p className="text-md text-black mt-4">Catering to businesses in the beauty, cosmetics,
                            and personal care industries. With our extensive range of high-
                            quality fragrances, exceptional customer service, and
                            competitive pricing, we are committed to helping your
                            business flourish.
                        </p>
                        <button onClick={ScrollToSection('welcome')}
                            className="bg-blue rounded-22 h-[46px] w-[154px] text-white mt-6 hover:bg-orange z-30">
                            Read more
                        </button>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-5 mt-[120px]">
                    <Layer2Item number="800" title="Different brands" />
                    <Layer2Item number="20 000" title="Different products" />
                    <Layer2Item number="25" title="Countris" />
                    <Layer2Item number="1 000" title="Satisfied customers" />
                </div>
            </div>
        </section>
    )
}

export default Layer2