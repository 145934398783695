const faq = [
    {
        id: 1,
        question: "Are the fragrances you offer authentic and original?",
        answer: "Yes, we take pride in providing only genuine and authentic fragrances sourced directly from reputable manufacturers and distributors. We value the trust of our B2B customers and ensure that every product in our inventory meets our stringent standards for quality and authenticity."
    },
    {
        id: 2,
        question: "Are there any minimum order quantities or value requirements?",
        answer: "We do not impose minimum order quantities or value requirements. Whether you need a small batch or a large wholesale order, we are here to accommodate your specific needs and help you find the right fragrance solutions for your business."
    },
    {
        id: 3,
        question: "Do you offer expedited shipping options?",
        answer: "At this time, we currently do not offer expedited shipping options. However, our standard delivery time of 4-7 business days ensures that your products will be delivered reliably within a reasonable timeframe."
    },
    {
        id: 4,
        question: "What is the estimated delivery time for my wholesale order?",
        answer: "Our standard delivery time for wholesale orders is 4-7 business days. Please note that delivery times may vary depending on your location and any unforeseen circumstances. Rest assured, we strive to process and dispatch orders promptly to minimize any delays."
    },
    {
        id: 5,
        question: "Can I return products if I am not satisfied with my purchase?",
        answer: "As a B2B fragrance wholesaler, we do not accept returns unless the products are damaged or defective upon arrival. We recommend thoroughly reviewing your order before finalizing it to ensure it meets your requirements."
    },
    {
        id: 6,
        question: "Can I cancel my order after it has been placed?",
        answer: "Once the order has been processed and the shipping process has started, we are unable to cancel the order. We recommend reviewing your order carefully before submitting it to ensure it meets your requirements."
    },
]

export default faq;