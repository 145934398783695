import React from "react";
import { sendEmail } from "../../app/Contact/sendEmail";
import { useAppDispatch } from "../../app/hooks";
import { notify } from "../../utils/notify";
type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const ContactUs = ({ ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let args = {};
    Object.values(e.target).forEach((value: any) => {
      const place = value.name;
      const value1 = value.value;
      if (place && value1) args = { ...args, [place]: value1 };
    });
    console.log("args", args);
    // @ts-ignore
    dispatch(sendEmail(args));
  };
  return (
    <section className="bg-white pb-20 hidden" {...rest}>
      <h2 className="text-lg lg:text-[42px] text-dark-blue text-center font-semibold">
        Feel free to contact with us
      </h2>
      <div className="grid grid-cols-12 responsive mt-[75px] gap-7">
        <form
          className="col-span-full lg:col-span-8 grid grid-cols-2 gap-[13px]"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            placeholder="Your name"
            name="name"
            required
            className="col-span-1 h-10 shadow-secondary rounded-22 border-solid border-[#EBEBEB] border px-3 outline-none"
          />
          <input
            type="email"
            placeholder="Your e-mail"
            name="email"
            required
            className="col-span-1 h-10 shadow-secondary rounded-22 border-solid border-[#EBEBEB] border px-3 outline-none"
          />
          <input
            type="text"
            placeholder="Subject"
            name="subject"
            required
            className="col-span-2 h-10 shadow-secondary rounded-22 border-solid border-[#EBEBEB] border px-3 outline-none"
          />
          <textarea
            name="message"
            required
            className="shadow-secondary col-span-full rounded-22 border-solid border-[#EBEBEB] border resize-none 
                        h-[142px] p-3 outline-none"
          />
          <button
            type="submit"
            className="bg-blue rounded-22 col-span-full h-[46px] text-white text-md hover:bg-orange"
          >
            Send a message
          </button>
        </form>
        <div className="col-span-full lg:col-span-4 flex flex-col text-md items-center lg:items-start">
          <p className="text-black text-justify">
            <span className="font-bold">
              We are here to assist you and answer any questions you may have.
            </span>
            &nbsp; Reach out to our friendly team today and let us help you find
            the perfect fragrance solutions for your business.
          </p>
          <p className="text-blue mt-4">noreply@envato.com</p>
          <img
            className="w-[98px] h-[107px] mt-auto"
            src="/img/contact/contact.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
