// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide the scrollbar */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e6a87c #f12d5533;
  -webkit-tap-highlight-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 15px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e6a87c;
  border-radius: 15px;
}

.Toastify__progress-bar-theme--light {
  background: linear-gradient(90deg, rgba(230, 168, 124, 1) 0%, rgba(81, 180, 190, 1) 62%) !important;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA,YAAY;AACZ;EACE,qBAAqB;EACrB,kCAAkC;EAClC,wCAAwC;AAC1C;;AAEA,6BAA6B;AAC7B;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mGAAmG;AACrG","sourcesContent":["/* Hide the scrollbar */\nbody::-webkit-scrollbar {\n  width: 0.5em;\n}\n\nbody::-webkit-scrollbar-thumb {\n  background-color: transparent;\n}\n\n/* Firefox */\n* {\n  scrollbar-width: thin;\n  scrollbar-color: #e6a87c #f12d5533;\n  -webkit-tap-highlight-color: transparent;\n}\n\n/* Chrome, Edge, and Safari */\n*::-webkit-scrollbar {\n  width: 4px;\n}\n\n*::-webkit-scrollbar-track {\n  background: #F5F5F5;\n  border-radius: 15px;\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: #e6a87c;\n  border-radius: 15px;\n}\n\n.Toastify__progress-bar-theme--light {\n  background: linear-gradient(90deg, rgba(230, 168, 124, 1) 0%, rgba(81, 180, 190, 1) 62%) !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
