import React from 'react'
import Layer1Item from '../../basics/Layer1Item'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>;

const Layer1 = ({ ...rest }: Props) => {
    return (
        <section className="relative flex flex-col mt-12" {...rest}>
            <div className="absolute">
                <img src="/img/layers/layer1/layer1.png" alt="" />
            </div>
            <div className="w-10 h-10 rounded-full border-2 border-blue text-blue mx-auto">
                <p className="text-center mt-1">1</p>
            </div>
            <h2 className="text-center text-lg lg:text-[42px] font-semibold text-dark-blue mt-4">Key benefits and <br />our goals</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 responsive">
                <Layer1Item svg={<img src="/img/layers/layer1/stocklist.png" alt="" />}
                    title="Update Stocklist"
                    description="Explore our vast stocklist,to meet the needs of our B2B customers." />
                <Layer1Item svg={<img src="/img/layers/layer1/support.png" alt="" />}
                    title="Great Support"
                    description="We are always ready to provide exceptional support and guidance." />
                <Layer1Item svg={<img src="/img/layers/layer1/process.png" alt="" />}
                    title="Safe Process"
                    description="We prioritize the safety of your transactions by implementing robust measures." />
                <Layer1Item svg={<img src="/img/layers/layer1/protect.png" alt="" />}
                    title="Data protect"
                    description="Your privacy is important to us, and we adhere to strict policies to ensure the confidentiality." />
            </div>
        </section>
    )
}

export default Layer1