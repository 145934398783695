import React from "react";
import BrandItem from "../basics/BrandItem";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

const Brands = ({ ...rest }: Props) => {
  return (
    <section className="flex flex-col responsive mt-10 lg:mt-[222px]" {...rest}>
      <h1 className="text-dark-blue font-semibold text-lg lg:text-[42px] text-center lg:text-start">
        We work with the best brands
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-14">
        <BrandItem brand="ck" width="w-[139px]" height="h-[22px]" />
        <BrandItem brand="bvl" width="w-[181px]" height="h-[74px]" />
        <BrandItem brand="dg" width="w-[215px]" height="h-[29px]" />
        <BrandItem brand="ysl" width="w-[177px]" height="h-[52px]" />
      </div>
    </section>
  );
};

export default Brands;
